/* 核验分类列表 */
.facebody-category-page {
  position: 'relative';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5.3333vw 0;
}

.facebody-category-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/images/facebody-bg1.png');
  background-size: cover;
  z-index: -1; 
}

.facebody-category-box {
  margin-bottom: 4.2667vw;
  width: 91.4667vw;
  padding: 4.2667vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgba(68,109,222,0.1);
  border-radius: 3.2vw;
  border: 1px solid #FFFFFF;
}

.facebody-category-title {
  font-weight: 500;
  font-size: 4.5333vw;
}

.facebody-category-status {
  margin-top: 2.1333vw;
  margin-bottom: 4.8vw;
  width: 13.3333vw;
  height: 5.8667vw;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebody-category-description {
  margin-bottom: 6.4vw;
  color: #999495;
}

.facebody-category-button {
  height: 9.6vw;
  background: #446DDE;
  border-radius: 2.1333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.2667vw;
  color: #FFFFFF;
}

/* 核验登录 */
.facebody-login-page {
  position: 'relative';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6.4vw 10.6667vw;
  box-sizing: border-box;
}

.facebody-login-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/images/facebody-bg2.png');
  background-size: cover;
  z-index: -1; 
}

.facebody-login-merchantName {
  margin-top: 6.4vw;
  font-weight: 500;
  font-size: 7.4667vw;
  color: #262525;
}

.facebody-login-categoryName {
  margin-top: 5.3333vw;
  font-weight: 500;
  font-size: 5.3333vw;
  color: #262525;
}

.facebody-login-form {
  margin-top: 10.6667vw;
  display: flex;
  flex-direction: column;
}

.facebody-login-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 6.4vw;
}

.facebody-login-label {
  font-size: 4.2667vw;
  color: #262525;
}

.facebody-login-input {
  margin-top: 3.2vw;
  width: 78.6667vw;
  height: 11.2vw;
  background: #FFFFFF;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid #446DDE;
  padding: 3.2vw;
  box-sizing: border-box;
}

.facebody-login-input::placeholder {
  color: #D2D4D9;
}

.facebody-login-button {
  position: fixed;
  bottom: 8vw;
  width: 78.6667vw;
  height: 10.6667vw;
  background: #446DDE;
  border-radius: 100px;
  color: #fff;
  font-size: 4.2667vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* 学生信息 */
.facebody-info-page {
  position: 'relative';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6.4vw 10.6667vw;
  box-sizing: border-box;
}

.facebody-info-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/images/facebody-bg3.png');
  background-size: cover;
  z-index: -1; 
}

.facebody-info-photo {
  margin-top: 10.6667vw;
  width: 32vw;
  height: auto;
}

.facebody-info-photo img{
  width: 100%;
  height: auto;
  background: #446DDE;
  box-shadow: 0px 4px 20px 0px rgba(68,109,222,0.3);
  border-radius: 3.2vw;
  border: 2px solid #FFFFFF;
}

.facebody-info-item {
  margin-top: 8.5333vw;
}

.facebody-info-label {
  font-size: 4.2667vw;
  color: #999495;
}

.facebody-info-content {
  margin-top: 3.2vw;
  font-weight: 500;
  font-size: 5.3333vw;
  color: #262525;
}

.facebody-info-button {
  position: fixed;
  bottom: 25.0667vw;
  width: 78.6667vw;
  height: 10.6667vw;
  background: #446DDE;
  border-radius: 100px;
  color: #fff;
  font-size: 4.2667vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* 准备核验 */
.facebody-prepare-page {
  position: 'relative';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8vw;
  box-sizing: border-box;
}

.facebody-prepare-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/images/facebody-bg4.png');
  background-size: cover;
  z-index: -1; 
}

.facebody-prepare-title {
  width: 100%;
  margin-top: 10.6667vw;
  font-weight: 500;
  font-size: 6.9333vw;
  color: #262525;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebody-prepare-example {
  margin-top: 10.6667vw;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebody-prepare-example img{
  width: 73.6vw;
  height: auto;
}

.facebody-prepare-tags {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.facebody-prepare-tag {
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebody-prepare-tag img{
  width: 3.4667vw;
  height: auto;
}

.facebody-prepare-tag span{
  color: #262525;
}

.facebody-prepare-button {
  position: fixed;
  bottom: 25.0667vw;
  width: 78.6667vw;
  height: 10.6667vw;
  background: #446DDE;
  border-radius: 100px;
  color: #fff;
  font-size: 4.2667vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* 活体采集 */
.facebody-capture-page {
  position: 'relative';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.facebody-capture-box {
  margin-top: 34.6667vw;
  width: 100%;
  height: auto;
}

.facebody-capture-box img{
  width: 100%;
  height: auto;
}

.facebody-capture-video {
  position: absolute;
  top: 47.4667vw;
  left: 14.6667vw;
  width: 70.4vw;
  height: 70.4vw;
  border-radius: 50%;
  background: #262525;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebody-capture-video video{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #262525;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebody-capture-prompt {
  font-weight: 500;
  font-size: 7.4667vw;
  color: #262525;
}


/* 核验结果 */
.facebody-result-page {
  position: 'relative';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8vw;
  box-sizing: border-box;
}

.facebody-result-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/images/facebody-bg5.png');
  background-size: cover;
  z-index: -1; 
}

.facebody-result-icon {
  margin-top: 17.0667vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebody-result-icon img{
  width: 12.8vw;
  height: 12.8vw;
}

.facebody-result-title {
  margin-top: 5.3333vw;
  font-weight: 500;
  font-size: 4.5333vw;
  color: #262525;
}

.facebody-result-description {
  margin-top: 2.1333vw;
  color: #999495;
  text-align: center;
  line-height: 6.9333vw;
}

.facebody-result-button {
  position: fixed;
  bottom: 25.0667vw;
  width: 78.6667vw;
  height: 10.6667vw;
  background: #446DDE;
  border-radius: 100px;
  color: #fff;
  font-size: 4.2667vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
